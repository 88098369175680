import React, {useEffect} from "react";
import Translations from "../Common/Translations";
import { Box, Grid, Typography, Button} from "@mui/material";
import { NavLink } from "react-router-dom";

function BlogItem(props) {
  const {image, title, description, link, size, length} = props
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Grid item xs={12} sm={12} md={size}>
        <Box className="npImg">
          <NavLink to={link}><img src={image} alt={title} /></NavLink>
        </Box>
        <Box className="npContent">
          <Typography variant="h3">{title}</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: `${description?.substr(0,length)}...`}}></Typography>
          <Button href={link} ><Translations text="Read more" /></Button>
        </Box>
      </Grid>
    </>
  );
}

export default BlogItem;
