import axios from 'axios';
import { API_URL,paypalDetails,KlarnaDetails } from '../../config/config';
import { getUserToken } from '../../utils/util';
import { Buffer } from "buffer";

export const productListApi = async (slug) => {
  // Construct query string from slug object
  const queryParams = new URLSearchParams();
  if (slug) {
    queryParams.append('category_slug', slug);
  }

  const queryString = queryParams.toString();
  const url = `${API_URL}/product-list${queryString ? `?${queryString}` : ''}`;
  
  const response = await axios.get(url);
  return response.data;
};


export const productDetailApi = async (slug) => {
  // Construct query string from slug object
  const queryParams = new URLSearchParams();
  
  if (slug) {
    queryParams.append('slug', slug);
  }
  const queryString = queryParams.toString();
  const url = `${API_URL}/product-details${queryString ? `?${queryString}` : ''}`;
  
  const response = await axios.get(url);
  return response.data;
};


export const questionsApi = async (category_id) => {
  const userTokenData = getUserToken('persist:root')
  // Construct query string from slug object
  const queryParams = new URLSearchParams();
  
  if (category_id) {
    queryParams.append('category_id', category_id);
  }
  const queryString = queryParams.toString();
  const url = `${API_URL}/questions${queryString ? `?${queryString}` : ''}`;
  
  const response = await axios.get(url,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const addToCartApi = async (cartData) => {
  const userTokenData = getUserToken('persist:root')
  
  const url = `${API_URL}/update-cart`;
  const response = await axios.post(url,{cart_data:cartData},{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const getCartApi = async () => {
  const userTokenData = getUserToken('persist:root')
  
  const url = `${API_URL}/cart`;
  const response = await axios.get(url,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const checkoutApi = async (data) => {
  const userTokenData = getUserToken('persist:root')
  
  const url = `${API_URL}/checkout`;
  const response = await axios.post(url,data,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const applyCouponCodeApi = async (data) => {
  const userTokenData = getUserToken('persist:root')
  
  const url = `${API_URL}/verify-coupon`;
  const response = await axios.post(url,data,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const generateAccessToken = async() => {
  try {
    const auth = Buffer.from(`${paypalDetails.clientId}:${paypalDetails.clientSecret}`).toString("base64");
    const response = await fetch(`${paypalDetails.baseUrl}/v1/oauth2/token`, {
      method: "POST",
      body: "grant_type=client_credentials&ignoreCache=true&return_authn_schemes=true&return_client_metadata=true&return_unconsented_scopes=true",
      headers: {
        Authorization: `Basic ${auth}`,
        "Content-Type": "application/x-www-form-urlencoded"
      },
    });

    const data = await response.json();
    return data.access_token;
  } catch (error) {
    console.error("Failed to generate Access Token:", error);
  }
}
export const paypalCreateOrder = async(accessToken, cartData) => {
  try {
    const response = await fetch(`${paypalDetails.baseUrl}/v2/checkout/orders`, {
      method: "POST",
      body: JSON.stringify(cartData),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to generate Access Token:", error);
  }
}

export const capturePayment = async(orderId) => {
  const accessToken = await generateAccessToken();
  const url = `${paypalDetails.baseUrl}/v2/checkout/orders/${orderId}/capture`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await response.json();
  return data;
}

export const updateOrderStatusWithDetail = async (accessToken,data) => {
  const url = `${API_URL}/order-payment`;
  const response = await axios.post(url,data,{
    headers: {  Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};


export const klarnaCreateOrder = async(requestData) => {
  const url = `${KlarnaDetails.apiURL}/payments/v1/sessions`;
  const auth = Buffer.from(`${KlarnaDetails.apiKey}:${KlarnaDetails.apiSecret}`).toString("base64")
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(requestData),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${auth}`,
    },
  });
  const data = await response.json();
  return data;
}

export const getActivePaymentMethod = async () => {
  const userTokenData = getUserToken('persist:root')
  
  const url = `${API_URL}/app-setting`;
  const response = await axios.get(url,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};