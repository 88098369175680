import React, { useState, useEffect } from "react";
import Footer from "../../../src/components/Common/Footer";
import Header from "../../../src/components/Common/Header";
import OfferNotificationBar from "../../../src/components/Common/OfferNotificationBar";
import Translations from "../../components/Common/Translations";
import { Typography, Container, Grid, } from "@mui/material";
import StaticPageTabs from './StaticPageTabs'
import PageContent from './PageContent'
import FaqContent from './FaqContent'
import { useLocation } from 'react-router-dom';

function StaticPage() {
  const [activeTab, setActiveTab] = useState(0);
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(()=>{
    const activeT = location.pathname?.indexOf('refund-returns') > -1 ? 1 : location.pathname?.indexOf('privacy-policy') > -1  ? 2 : location.pathname?.indexOf('legal-notice') > -1 ? 4 : location.pathname?.indexOf('frequently-asked-questions') > -1 ? 3 : 0
    setActiveTab(activeT)
  },[location])

  const handleTabChange = (activeTab) => {
    setActiveTab(activeTab);
  };

  return (
    <>
      <OfferNotificationBar />
      <Header />
      <section className="legal_header" style={{ borderBottom: "2px solid #fbaa1a" }}>
        <Container maxWidth={"xl"}>
              <Typography
                variant="h1"
                sx={{ textAlign: "center" }}
              >
                <Translations text="Legal" />
              </Typography>
        </Container>
      </section>
      <section className="legal_content">
        <Container maxWidth={"xl"}>
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <StaticPageTabs
                handleTabChange={handleTabChange}
                activeTab={activeTab}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              {activeTab === 0 && <PageContent slug="terms-and-conditions" />}
              {activeTab === 1 && <PageContent slug="delivery-and-returns" />}
              {activeTab === 2 && <PageContent slug="privacy-policy" />}
              {activeTab === 3 && <FaqContent title="frequently-asked-questions" />}
              {activeTab === 4 && <PageContent slug="imprint" />}
            </Grid>
          </Grid>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default StaticPage;
