import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Translations from "./Translations";
import { getCartApi } from "../../features/product/productApi";
import CookieConsent from "react-cookie-consent";
// import {siteMapApi} from '../../features/common/commanApi'
function Footer() {
  const { t } = useTranslation();
  const { categories } = useSelector((state) => state?.common);
  const { userData } = useSelector((state) => state.user || '');

  useEffect(() => {
    (async () => {
      try {
        const currentCart = window.localStorage.getItem("cartData")
        if (userData?.data?.id && (currentCart === null || currentCart === undefined || currentCart === '')) {
          const cartData = await getCartApi();
          if (cartData?.data?.cart_data) {
            window.localStorage.setItem("cartData",cartData?.data?.cart_data);
            window.dispatchEvent(new Event("cartUpdate"));
          }
        }
      } catch (error) {}
    })();
  }, [userData?.data?.id]);

  // const generateSiteMap = async() => {
  //   const siteMapdata = await siteMapApi()
  // }

  return (
    <footer
      className="footer_wrap"
      style={{ background: "#ddf0ff", padding: "32px 0px" }}
    >
      <Container maxWidth={"xl"}>
        <Grid container sx={{ gap: "2rem", justifyContent: "space-between" }}>
          <Grid item xs={12} sm={2.4} md={3} className="footer_logo">
            <Box>
            {/* <a href="https://www.aanbiedersmedicijnen.nl/aanbieders/aanbiederslijst/prime-pharmacy-b.v.-6/index" target="_blank" rel="noreferrer"> */}
              <a href="/" rel="noreferrer">
                <img
                  src="/images/apotheke-premium-logo.webp"
                  alt=""
                  width={200}
                />
              </a>
              <div style={{ marginTop: "20px" }}>
                <a href="https://www.aanbiedersmedicijnen.nl/aanbieders/aanbiederslijst/prime-pharmacy-b.v.-9/index" target="_blank" rel="noreferrer">
                  <img
                    src="/images/LogoSancoInternet_NL1.webp"
                    alt=""
                    width={200}
                  />
                </a>
              </div>
              <div className="footer_infoBx">
                <p>
                  {/* <a href="https://www.aanbiedersmedicijnen.nl/aanbieders/aanbiederslijst/prime-pharmacy-b.v/index" target="_blank" rel="noreferrer"> */}
                  <span>Der Verkauf und die Lieferung der Arzneimittel erfolgt durch die Online Apotheke Prime Pharmacy B.V. | De Gruisdonk 9 B, 5928RT Venlo, The Netherlands</span>
                </p>
                <p><strong>E-mail:</strong> <a href="mailto:info@prime-pharmacy.eu">info@prime-pharmacy.eu</a> <br></br> <strong>Phone:</strong> <a href="tel: +31 (0)77 23 000 16">+31 (0)77 23 000 16</a></p>
              </div>
            </Box>
          </Grid>
          <Grid className="footer_col" item xs={12} md={3}>
            <Typography
              component={"h3"}
              sx={{ color: "#0f3f83", fontWeight: "600" }}
            >
              <Translations text="Navigation" />
            </Typography>
            {categories?.data?.length && (
              <List>
                {categories?.data?.map((category, index) => (
                  <ListItem key={`fmenucat${index}`}>
                    <NavLink
                      to={category?.children?.length> 0 ? `/product-category/${category?.slug}`: `/product-category/${category?.slug}/${category?.id}`}
                      alt={category?.name}
                    >
                      {category?.name}
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
          <Grid className="footer_col" item xs={12} md={3}>
            <Typography
              component={"h3"}
              sx={{ color: "#0f3f83", fontWeight: "600" }}
            >
              <Translations text="Other" />
            </Typography>
            <List>
              <ListItem>
                <NavLink to="/blog" alt={`${t("Blog")}`}>
                  <Translations text="Blog" />
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/contact-us" alt={`${t("Contact us")}`}>
                  <Translations text="Contact us" />
                </NavLink>
              </ListItem>
              {/* <ListItem>
                <Link onClick={generateSiteMap} alt={`${t("Site map")}`}>
                  <Translations text="Site Map" />
                </Link>
              </ListItem> */}
            </List>
          </Grid>
          <Grid className="footer_col" item xs={6} md={3}>
            <Typography
              component={"h3"}
              sx={{ color: "#0f3f83", fontWeight: "600" }}
            >
              <Translations text="Legal" />
            </Typography>
            <List>
              <ListItem>
                <NavLink
                  to="/terms-and-conditions"
                  alt={`${t("Terms and Conditions")}`}
                >
                  <Translations text="Terms and Conditions" />
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  to="/refund-returns"
                  alt={`${t("Delivery & Returns")}`}
                >
                  <Translations text="Delivery & Returns" />
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/privacy-policy" alt={`${t("Privacy Policy")}`}>
                  <Translations text="Privacy Policy" />
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/legal-notice" alt={`${t("imprint")}`}>
                  <Translations text="Imprint" />
                </NavLink>
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ mt: "10px", display: "flex", gap: "1rem" }}
            className="footer_brands"
          >
            <Typography>
              <img src="/images/klarna.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/paypal.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/gpay.svg" alt="" />
            </Typography>
            {/* <Typography>
              <img src="/images/r.svg" alt="" />
            </Typography> */}
            <Typography>
              <img src="/images/mastercard.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/visa.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/american-express.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/dhl.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/fedex.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/ups.svg" alt="" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "12px" }}>
              <Translations text="All brands and registered trademarks are the property of their respective companies. Pharma Premium.de is not an (Internet) pharmacy and does not offer, sell, deliver or dispense any medicines or other products in any other way. The product information and prices on this website do not constitute an offer from Pharma Premium.de to you. Any use of this website and the services offered by Pharma Premium.de is at your own risk. You are responsible for compliance with the legal provisions applicable in your country and declare that you are visiting this website on your own initiative." />
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              <Translations text="Your personal information will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy ." />
            </Typography>
            <Divider
              color="#ffffff"
              sx={{ mt: "20px", backgroundColor: "#000" }}
            />
          </Grid>
          <Grid item xs={12} sx={{ fontSize: "14px" }}>
            © {new Date().getFullYear()} Pharma Premium
          </Grid>
          <CookieConsent 
            enableDeclineButton 
            flipButtons
            style={{
              background: "#ffffff",
              color: "#0f3f83",
              borderTop: "2px solid #fbab1b",
              margin: "10px 0px 0px",
              display:'block'
            }}
            buttonStyle={{
              background: "#fbab1b",
              color: "#ffffff",
              borderRadius:'5px'
            }}
            buttonText='Akzeptieren'
            declineButtonText="Ablehnen"
            declineButtonStyle={{
              background:'#ffffff',
              color: "#0f3f83",
              fontWeight: "500"
            }}
          >
            <Typography variant="h5" sx={{mb:'5px'}}>Cookie-Richtlinie</Typography>
            <Typography>Wir verwenden Cookies, um Inhalte zu personalisieren, Funktionen für soziale Medien bereitzustellen und unseren Datenverkehr zu analysieren. Wir teilen auch Informationen über Ihre Nutzung unserer Website mit unseren Partnern für soziale Medien, Werbung und Analysen. <NavLink to="/privacy-policy" alt={`${t("Privacy Policy")}`} className='coockeLink'>Datenschutzbestimmungen</NavLink></Typography>
          </CookieConsent>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
