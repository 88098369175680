import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { updateOrderStatusWithDetail } from "../../features/product/productApi";
import { sleep } from "../../utils/util";
import { Box, CircularProgress } from "@mui/material";

const KlarnaOrderStatus = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const hppId = searchParams.get("hppId");
  const sid = searchParams.get("sid");
  const token = searchParams.get("token");
  console.log(location.pathname, "location.pathname", searchParams);
  const [loading, setLoading] = useState(false);
  const path = location.pathname?.replace("/klarna-", "");
  const klarnaResponse = {
    status: path,
    hppId: hppId || "",
    sid: sid || "",
    token: token || "",
  };
  window.localStorage.setItem("klarnaResponse", JSON.stringify(klarnaResponse));

  const updateOrderStatus = async () => {
    let tokenData = "";
    let oId = "";
    const chData = window.localStorage.getItem("checkOutResponse");
    if (chData) {
      const ch = JSON.parse(chData);
      tokenData = ch?.token;
      oId = ch?.order_id;
      window.localStorage.setItem("tok", ch?.token);
    }
    const finalData = {
      order_id: oId,
      payment_status: "completed",
      transaction_id: hppId,
      paypal_capture_id: token,
      paymentMethod:'klarna',
      transaction_logs: JSON.stringify(klarnaResponse),
    };
    setLoading(true);
    try {
      const updateOrder = await updateOrderStatusWithDetail(
        tokenData,
        finalData
      );
      if (updateOrder?.status) {
        window.localStorage.removeItem("cartData");
        window.localStorage.removeItem("checkOutResponse");
        window.localStorage.removeItem("voucherData");
        window.dispatchEvent(new Event("cartUpdate"));
        window.dispatchEvent(new Event("klarnaResponse"));
        await sleep(1000);
        window.localStorage.setItem("oId", oId);
        setLoading(false);
        navigate("/order-success");
      } else {
        setLoading(false);
        navigate("/order-success");
      }
    } catch (error) {
      setLoading(false);
      navigate("/order-success");
    }
  };
  useEffect(() => {
    (async () => {
      if (path === "success") {
        await updateOrderStatus();
      } else if (path === "status_update") {
        navigate("/");
      } else {
        toast.error(`${t("Payment failed. Please try again.")}`);
        await sleep(500);
        navigate("/checkout");
      }
    })();
  }, [path]);

  return (
    <div>
      {loading && (
        <Box className="progressOverlay">
          <div className="progressBx">
            <CircularProgress />
          </div>
        </Box>
      )}
    </div>
  );
};

export default KlarnaOrderStatus;
