import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
    
  const user = useSelector((state) => state.user);
    console.log(user,'useruseruser')
    // If the user is not authenticated, redirect to the login page
    if (user?.first_name === undefined) {
        return <Navigate to="/my-account" replace />;
    }

    return <Outlet />; // Render the child routes if authenticated
};

export default ProtectedRoute;
