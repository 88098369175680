import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { updateOrderStatusWithDetail } from "../../features/product/productApi";
import { sleep } from "../../utils/util";

const StripeOrderStatus = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const status = searchParams.get("success");
  const cancel = searchParams.get("cancel");
console.log(status,'stripeStatus',searchParams, cancel)
  const updateOrderStatus = async () => {
    let tokenData = "";
    let oId = "";
    const chData = window.localStorage.getItem("checkOutResponse");
    if (chData) {
      const ch = JSON.parse(chData);
      tokenData = ch?.token;
      oId = ch?.order_id;
      window.localStorage.setItem("tok", ch?.token);
    }
    const finalData = {
      order_id: oId,
      payment_status: "completed",
      transaction_id: 'stripeTransactionId',
      paymentMethod:'stripe',
    };
    const updateOrder = await updateOrderStatusWithDetail(tokenData, finalData);
    if (updateOrder?.status) {
      window.localStorage.removeItem("cartData");
      window.localStorage.removeItem("checkOutResponse");
      window.localStorage.removeItem("voucherData");
      window.dispatchEvent(new Event("cartUpdate"));
      window.dispatchEvent(new Event("klarnaResponse"));
      await sleep(1000);
      window.localStorage.setItem("oId", oId);
      navigate("/order-success");
    } else {
      navigate("/order-success");
    }
  };
  useEffect(() => {
    (async () => {
      if (status) {
        await updateOrderStatus();
      } else {
        toast.error(`${t("Payment failed. Please try again.")}`);
        await sleep(500)
        navigate("/checkout");
      }
    })();
  }, [status]);

  return <div></div>;
};

export default StripeOrderStatus;
