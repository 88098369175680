import { Box, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthBanner from '../../src/components/AuthBanner';
import Header from '../../src/components/Common/Header';
import OfferNotificationBar from '../../src/components/Common/OfferNotificationBar';
import Footer from './Common/Footer';
import Login from './Login';
import Register from './Register';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

function AuthSwitcher() {
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);
    const [alignment, setAlignment] = useState('login');
    const navigate = useNavigate()

    useEffect(()=>{
        if(user?.first_name !=='' && user?.first_name !== undefined && user?.first_name !== null){
            navigate('/my-account/dashboard')
        }
    },[user?.first_name, navigate])

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    return (
        <div>
            <OfferNotificationBar />
            <Header />
            <Grid container>
                <AuthBanner />
                <Grid item sm={12} md={6} bgcolor={"rgb(238, 247, 254)"} className='login_right'>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <ToggleButtonGroup className='lrTab'
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            aria-label={t("authentication switch")}
                            sx={{ mb: 4 }}
                        >
                            <ToggleButton value="login">{t('Login')}</ToggleButton> {/* Use translation for button text */}
                            <ToggleButton value="register">{t('Register')}</ToggleButton> {/* Use translation for button text */}
                        </ToggleButtonGroup>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            {alignment === 'login' && <Login />}
                            {alignment === 'register' && <Register />}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Footer />
        </div>
    );
}

export default AuthSwitcher;
