import { Icon } from "@iconify/react";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import {
  Avatar,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Rating from "@mui/material/Rating";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Footer from "../../src/components/Common/Footer";
import Header from "../../src/components/Common/Header";
import KpiBoxes from "../../src/components/Common/KpiBoxes";
import OfferNotificationBar from "../../src/components/Common/OfferNotificationBar";
import Translations from "../components/Common/Translations";

function Home() {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { categories, faqs } = useSelector((state) => state?.common);

  const [sliderRef, instanceRef] = useKeenSlider({
    breakpoints: {
      "(min-width: 300px)": {
        slides: { perView: 1, spacing: 5 },
      },
      "(min-width: 992px)": {
        slides: { perView: 2, spacing: 40 },
      },
      "(min-width: 1200px)": {
        slides: { perView: 3, spacing: 50 },
      },
    },
    loop: true,
    mode: "free",
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  function Arrow(props) {
    const disabled = props.disabled ? " arrow--disabled" : "";
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabled}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    );
  }

  return (
    <>
      <OfferNotificationBar />
      <Header />
      <section style={{ background: "#eef7fe" }}>
        <Container maxWidth={"xl"}>
          <Grid container>
            <Grid className="shipping_block" item xs={12}>
              <KpiBoxes
                text={`${t("Premium Pharmacy")}`}
                icon="/images/top-icon1.svg"
              />
              <KpiBoxes
                text={`${t("Medical prescription")}`}
                icon="/images/top-icon2.svg"
              />
              <KpiBoxes
                text={`${t("Discreet packaging")}`}
                icon="/images/top-icon3.svg"
              />
              <KpiBoxes
                text={`${t("24-hour delivery")}`}
                icon="/images/top-icon4.svg"
              />
            </Grid>

            <Grid className="rating_block" item xs={12} sm={12}>
              <Rating
                className="rating_star"
                value={4.5}
                precision={0.5}
                readOnly
              />
              <Typography sx={{ mb: "30px", mt: "6px", fontSize: "14px" }}>
                <Translations text="4.7 / 5 based on over 2300 customers" />
              </Typography>
              <Typography variant="h1" sx={{ mb: "18px" }}>
                <Translations text="Your premium medications – delivered safely and discreetly directly to your home with your online prescription." />
              </Typography>
              {/* <Typography sx={{ fontSize: "18px" }}>
                <Translations text="Trusted and regulated by the EU Pharmacy Council Green Cross Logo. We offer an online e-prescription service for German medicines dispensed by EU registered pharmacies for next day delivery via DHL." />
              </Typography> */}
            </Grid>
          </Grid>
          {categories?.data?.length > 0 && (
            <Grid container className="home_services">
              {categories?.data?.map((category, index) => {
                return (
                  <KpiBoxes
                    text={category?.name}
                    icon={category?.logo || "/images/top-icon2.svg"}
                    size="4"
                    link={
                      category?.children?.length > 0
                        ? `/product-category/${category?.slug}`
                        : `/product-category/${category?.slug}/${category?.id}`
                    }
                    rightImage="/images/arrow.svg"
                    backgroundColor="#ffffff"
                    key={`category${index}`}
                  />
                );
              })}
            </Grid>
          )}
        </Container>
      </section>
      <section className="spc_wrap" style={{ background: "#ddf0ff" }}>
        <Container maxWidth={"xl"}>
          <Grid container className="spc_inner">
            <Grid className="spc_img" xs={12} sm={12} md={6}>
              <img src="/images/visual-man-with-phone.webp" alt="" />
            </Grid>
            <Grid xs={12} sm={12} md={6} className="spc_content">
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot>1</TimelineDot>
                    <TimelineConnector sx={{ background: "#fbab1b" }} />
                  </TimelineSeparator>
                  <TimelineContent sx={{ padding: "8px 0 16px 16px" }}>
                    <Typography
                      component={"h4"}
                      sx={{ fontWeight: 700, color: "#0f3f83", mb: "4px" }}
                    >
                      <Translations text="Select treatment" />
                    </Typography>
                    <Typography sx={{ color: "#0f3f83" }}>
                      <Translations text="Choose your medication from our selection and answer a few simple and quick questions to make sure it is the right choice for you." />
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot>2</TimelineDot>
                    <TimelineConnector sx={{ background: "#fbab1b" }} />
                  </TimelineSeparator>
                  <TimelineContent sx={{ padding: "8px 0 16px 16px" }}>
                    <Typography
                      component={"h4"}
                      sx={{ fontWeight: 700, color: "#0f3f83", mb: "4px" }}
                    >
                      <Translations text="Prescription approval" />
                    </Typography>
                    <Typography sx={{ color: "#0f3f83" }}>
                      <Translations text="Your questionnaire will be carefully reviewed by an experienced and licensed doctor. Your medication will then be shipped safely and discreetly from our pharmacy in the Netherlands." />
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot>3</TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent sx={{ padding: "8px 0 16px 16px" }}>
                    <Typography
                      component={"h4"}
                      sx={{ fontWeight: 700, color: "#0f3f83", mb: "4px" }}
                    >
                      <Translations text="Courier delivery" />
                    </Typography>
                    <Typography sx={{ color: "#0f3f83" }}>
                      <Translations text="Use our express service and receive your medication the very next day after you place your order to ensure you receive the supply you need without delay." />
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className="packaging_wrap" style={{ background: "#eef7fe" }}>
        <Container maxWidth={"xl"}>
          <Grid container className="packaging_inner" alignItems={"center"}>
            <Grid className="packaging_content" xs={12} sm={12} md={6}>
              <Typography variant="h5" sx={{ fontWeight: 700 }}>
                <Translations text="Discreet packaging" />
              </Typography>
              <Typography>
                <Translations text="We pride ourselves on offering fast and discreet delivery for all our medications and treatments, so you'll receive your order quickly and only you will know what's in it." />
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: "none",
                        color: "#fbab1b",
                        transform: "rotate(-90deg)",
                      }}
                    >
                      <Icon icon="fluent:teardrop-bottom-right-16-filled" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={`${t("No logos")}`} />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: "none",
                        color: "#fbab1b",
                        transform: "rotate(-90deg)",
                      }}
                    >
                      <Icon icon="fluent:teardrop-bottom-right-16-filled" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${t("No mention of the pharmacy")}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: "none",
                        color: "#fbab1b",
                        transform: "rotate(-90deg)",
                      }}
                    >
                      <Icon icon="fluent:teardrop-bottom-right-16-filled" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${t("No description of the content")}`}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid xs={12} sm={12} md={6} className="packaging_img">
              <img src="/images/package.webp" alt="" />
            </Grid>
          </Grid>
        </Container>
      </section>
      {faqs?.data?.length > 0 && (
        <section className="faq_home" style={{ background: "#ddf0ff" }}>
          <Container maxWidth={"xl"}>
            <Typography
              className="faq_home_title"
              variant="h3"
              sx={{
                textAlign: "center",
                fontWeight: 700,
                color: "#0f3f83",
              }}
            >
              <Translations text="Frequently Asked Questions" />
            </Typography>
            <Grid container className="faq_accordion">
              {faqs?.data?.map((faq, index) => (
                <>
                  {index < 6 && (
                    <Grid
                      key={`faq${index}`}
                      item
                      sx={{ color: "#0f3f83" }}
                      className="faq_home_items"
                    >
                      <Accordion sx={{ background: "none", boxShadow: "none" }}>
                        <AccordionSummary
                          sx={{ padding: "0" }}
                          expandIcon={
                            <Icon
                              color="#fbab1b"
                              fontSize={30}
                              icon="ri:arrow-down-s-line"
                            />
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: 14,
                              color: "#0f3f83",
                              fontWeight: 500,
                            }}
                          >
                            {faq?.question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: "0 0 20px" }}>
                          <Typography
                            sx={{ fontSize: 14, color: "#0f3f83" }}
                            dangerouslySetInnerHTML={{ __html: faq?.answer }}
                          ></Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Divider sx={{ borderColor: "#0f3f83" }} />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </Container>
        </section>
      )}
      <section style={{ background: "#eef7fe" }} className="ocs_wrap">
        <Container maxWidth={"xl"}>
          <Typography
            className="ocs_title"
            variant="h5"
            sx={{
              textAlign: "center",
              fontWeight: 700,
              color: "#0f3f83",
            }}
          >
            <Translations text="What our customers say" />
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <div className="navigation-wrapper">
                <div ref={sliderRef} className="keen-slider">
                  <div className="keen-slider__slide number-slide1">
                    <Rating value="5" readOnly />
                    <Typography>
                      Care Communications Solutions ist zu meinem vertrauenswürdigen
                      Gesundheitspartner geworden. Ihre Website ist
                      benutzerfreundlich und bietet eine umfangreiche Auswahl an
                      Medikamenten, sowohl verschreibungspflichtig als auch
                      rezeptfrei. Was sie auszeichnet, ist ihr
                      unerschütterliches Engagement für Qualität und Sicherheit,
                      um sicherzustellen, dass alle Produkte EU-zugelassen sind
                      und von seriösen Herstellern bezogen werden.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, mt: "auto" }}>
                      Herbert Müller
                    </Typography>
                  </div>
                  <div className="keen-slider__slide number-slide2">
                    <Rating value="5" readOnly />
                    <Typography>
                      Die Bestellung ist ein Kinderspiel, und ihre lizenzierten
                      Apotheker garantieren eine genaue Rezeptabwicklung. Die
                      wettbewerbsfähigen Preise machen Gesundheitsversorgung
                      erschwinglicher, und ihr diskreter, pünktlicher Versand
                      ist ein großer Pluspunkt. Ich schätze die Privatsphäre,
                      die ihre diskrete Verpackung bietet.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, mt: "auto" }}>
                      Erika Schmidt
                    </Typography>
                  </div>
                  <div className="keen-slider__slide number-slide3">
                    <Rating value="5" readOnly />
                    <Typography>
                      Der Kundensupport von Care Communications Solutions ist erstklassig;
                      sie sind immer ansprechbar und hilfsbereit. Diese
                      Online-Apotheke setzt wirklich auf Kundenzufriedenheit und
                      bietet eine nahtlose und zuverlässige
                      Gesundheitserfahrung.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, mt: "auto" }}>
                      Klaus Fischer
                    </Typography>
                  </div>
                  <div className="keen-slider__slide number-slide4">
                    <Rating value="5" readOnly />
                    <Typography>
                      Für Bequemlichkeit, Erschwinglichkeit und ein beruhigendes
                      Gefühl kann ich Care Communications Solutions wärmstens empfehlen.
                      Egal, ob Sie Rezepte oder Wellnessprodukte benötigen, sie
                      liefern konsequent Exzellenz in jeder Service-Hinsicht.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, mt: "auto" }}>
                      Anna Wagner
                    </Typography>
                  </div>
                  <div className="keen-slider__slide number-slide5">
                    <Rating value="5" readOnly />
                    <Typography>
                    Care Communications Solutions bietet eine nahtlose
                      Online-Apothekenerfahrung. Ihre umfangreiche Auswahl an
                      hochwertigen Medikamenten und Gesundheitsprodukten stellt
                      sicher, dass Sie finden, was Sie brauchen. Mit sicheren
                      Transaktionen und diskreter Verpackung steht Ihre
                      Privatsphäre an erster Stelle. Expertenapotheker stehen
                      für persönliche Beratung zur Verfügung, und schneller
                      Versand garantiert, dass Sie Ihre Essentials rechtzeitig
                      erhalten. Vertrauen Sie Care Communications Solutions für bequeme,
                      zuverlässige Gesundheitslösungen.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, mt: "auto" }}>
                      Markus Weber
                    </Typography>
                  </div>
                  <div className="keen-slider__slide number-slide6">
                    <Rating value="5" readOnly />
                    <Typography>
                      Ich empfehle Care Communications Solutions wärmstens! Ihre umfangreiche
                      Palette an pharmazeutischen Produkten, schnelle Lieferung
                      und außergewöhnlicher Kundenservice machen sie zu meiner
                      ersten Wahl für all meine Gesundheitsbedürfnisse.
                      Vertrauenswürdig und zuverlässig, setzt Care Communications Solutions
                      den Standard für Online-Apotheken.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, mt: "auto" }}>
                      Sophie Müller
                    </Typography>
                  </div>
                  <div className="keen-slider__slide number-slide6">
                    <Rating value="5" readOnly />
                    <Typography>
                      Leider habe ich eine peinliche medizinische Erkrankung,
                      und ich habe meine reguläre Apotheke benutzt. Care Communications Solutions war die dritte Online-Apotheke, die ich
                      ausprobiert habe, und ich bleibe bei ihnen. Die
                      Schnelligkeit des Service und der Preis sind unschlagbar.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, mt: "auto" }}>
                      Maximilian Lehmann
                    </Typography>
                  </div>
                  <div className="keen-slider__slide number-slide6">
                    <Rating value="5" readOnly />
                    <Typography>
                      Meine Mutter ist älter, und deshalb kümmere ich mich um
                      alle Medikamente für sie. Ich habe einen Vollzeitjob, und
                      dieser Online-Apotheken-Premiumdienst hat mein Leben so
                      viel einfacher gemacht.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, mt: "auto" }}>
                      Helga Schneider
                    </Typography>
                  </div>
                  <div className="keen-slider__slide number-slide6">
                    <Rating value="5" readOnly />
                    <Typography>
                      Normalerweise schreibe ich keine Bewertungen, aber ich
                      habe ein so professionelles Niveau bei der Bestellung von
                      Rezepten und der Lieferung erlebt, dass ich es für wichtig
                      halte, anderen Menschen mit einer Bewertung zu helfen.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, mt: "auto" }}>
                      Thomas Becker
                    </Typography>
                  </div>
                  <div className="keen-slider__slide number-slide6">
                    <Rating value="5" readOnly />
                    <Typography>
                      Ich dachte, Amazon wäre gut, aber Care Communications Solutions ist wie
                      Amazon Prime mit diskretem Express, was ich schätze.
                    </Typography>
                    <Typography sx={{ fontWeight: 700, mt: "auto" }}>
                      Carolin Zimmermann
                    </Typography>
                  </div>
                </div>
                {loaded && instanceRef.current && (
                  <>
                    <Arrow
                      left
                      onClick={(e) =>
                        e.stopPropagation() || instanceRef.current?.prev()
                      }
                      disabled={currentSlide === 0}
                    />

                    <Arrow
                      onClick={(e) =>
                        e.stopPropagation() || instanceRef.current?.next()
                      }
                      disabled={
                        currentSlide ===
                        instanceRef.current.track.details.slides.length - 1
                      }
                    />
                  </>
                )}
                {loaded && instanceRef.current && (
                  <div className="ksDots">
                    {[
                      ...Array(
                        instanceRef.current.track.details.slides.length
                      ).keys(),
                    ].map((idx) => {
                      return (
                        <button
                          key={idx}
                          onClick={() => {
                            instanceRef.current?.moveToIdx(idx);
                          }}
                          className={
                            "ksDot" + (currentSlide === idx ? " active" : "")
                          }
                        ></button>
                      );
                    })}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container className="customer_service">
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="h3" sx={{ fontWeight: 700 }}>
                <Translations text="We are here for you" />
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                <Translations text="Our customer service is available Monday to Friday from 8 a.m. to 9 p.m. If you need urgent assistance, do not use this service. Call 112 or 116 in an emergency. Visit our help section." />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="csb">
              <div className="customer_service_bx">
                <NavLink to="tel:+4930520047104">
                  <img src="/images/phone.webp" alt="" />
                </NavLink>
                <NavLink to="tel:+4930520047104">
                  <Translations text="Phone" />
                </NavLink>
              </div>
              <div className="customer_service_bx" id="customIntercomButton">
                <NavLink>
                  <img src="/images/live-chat-icon.webp" alt="" />
                </NavLink>
                <NavLink>
                  <Translations text="Chat" />
                </NavLink>
              </div>
              <div className="customer_service_bx">
                <NavLink to="mailto:info@pharmapremium.de">
                  <img src="/images/plane.webp" alt="" />
                </NavLink>
                <NavLink to="mailto:info@pharmapremium.de">
                  <Translations text="E-mail" />
                </NavLink>
              </div>
              <div className="customer_service_bx">
                <NavLink to="/frequently-asked-questions">
                  <img src="/images/faq.webp" alt="" />
                </NavLink>
                <NavLink to="/frequently-asked-questions">
                  <Translations text="FAQ" />
                </NavLink>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Home;
