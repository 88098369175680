import { React, useEffect } from 'react';
import { Toaster } from 'react-hot-toast'; // Import Toaster
import { useTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import '../src/config/i18n';
import "../src/custom.css";
import AuthSwitcher from './components/AuthSwitcher';
import BlogDetail from './components/Blogs/BlogDetail';
import BlogList from './components/Blogs/BlogList';
import Category from './components/Category/Category';
import NotFound from './components/Common/notFound';
import Dashboard from './components/UserDashboard/OrderList';
import OrderDetail from './components/UserDashboard/OrderDetail';
import ForgotPassword from './components/ForgotPassword';
import Home from './components/Home';
import CheckoutPage from './components/Order/Checkout';
import KlarnaOrderStatus from './components/Order/KlarnaOrderStatus';
import StripeOrderStatus from './components/Order/StripeOrderStatus';
import OrderSuccess from './components/Order/OrderSuccess';
import ProductDetail from './components/Products/ProductDetail';
import ProductListing from './components/Products/ProductList';
import ProtectedRoute from './components/ProtectedRoute';
import ResetPassword from './components/ResetPassword';
import SearchList from './components/Search/SearchList';
import ContactUs from './components/StaticPages/ContactUs';
import StaticPage from './components/StaticPages/StaticPage';
import UserAddress from './components/UserDashboard/UserAddress';

function App() {
  const { i18n } = useTranslation()

  useEffect(() => {
    const defLang = window.localStorage.getItem('defaultLanguage')
    i18n.changeLanguage(defLang || 'de')
  }, [i18n])

  return (
      <Router>
        <Routes>
          <Route path="/my-account" element={<AuthSwitcher />} />
          <Route path="/" element={<Home />} />
          <Route path="/terms-and-conditions" element={<StaticPage />} />
          <Route path="/refund-returns" element={<StaticPage />} />
          <Route path="/privacy-policy" element={<StaticPage />} />
          <Route path="/legal-notice" element={<StaticPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<StaticPage />} />
          <Route path="/frequently-asked-questions" element={<StaticPage />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/my-account/reset-password" element={<ForgotPassword />} />
          <Route path="/password-reset/:token" element={<ResetPassword />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />
          <Route path="/stripe-status" element={<StripeOrderStatus />} />
          <Route path="/klarna-back" element={<KlarnaOrderStatus />} />
          <Route path="/klarna-cancel" element={<KlarnaOrderStatus />} />
          <Route path="/klarna-error" element={<KlarnaOrderStatus />} />
          <Route path="/klarna-failure" element={<KlarnaOrderStatus />} />
          <Route path="/klarna-success" element={<KlarnaOrderStatus />} />
          <Route element={<ProtectedRoute />}>
              <Route path="/my-account/dashboard" element={<Dashboard />} />
              <Route path="/my-account/dashboard/orders/:id" element={<OrderDetail />} />
              <Route path="/my-account/dashboard/addresses" element={<UserAddress />} />
            </Route>
          <Route path="/product-category/:categorySlug/:subCategorySlug" element={<ProductListing />} />
          <Route path="/shop/:categorySlug/:subCategorySlug/:productSlug" element={<ProductDetail />} />
          <Route path="/shop/:slug" element={<ProductDetail />} />
          {/* <Route path="/products/list" element={<ProductListing />} /> */}
          <Route path="/product/:productId" element={<ProductDetail />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/order-success" element={<OrderSuccess />} />
          <Route path="/product-category/:slug" element={<Category />} />
          <Route path="/search" element={<SearchList />} />
          {/* Other routes go here */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Toaster />
      </Router>
  );
}

export default App;
