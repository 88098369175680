import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../features/auth/authSlice";
import { setUser } from "../features/user/userSlice";

function Login() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const response = await dispatch(loginUser(data)).unwrap();
      console.log(response, "response");
      if (response?.status) {
        dispatch(setUser(response.data));
        setLoading(false)
        toast.success(t("Login successful"));
        navigate("/my-account/dashboard");
      } else {
        setLoading(false)
        toast.error(response?.message);
      }
    } catch (error) {
      setLoading(false)
      toast.error(t("Failed to login"));
    }
  };

  return (
    <>
      <Box
        className="login_form_inner"
        component="form"
        noValidate
        autoComplete="off"
        sx={{ width: "100%" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: t("Username or email address is required") }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("Username or email address")}
              fullWidth
              margin="normal"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: t("Password is required") }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("Password")}
              type={showPassword ? "text" : "password"}
              fullWidth
              margin="normal"
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("Toggle password visibility")}
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <FormControlLabel
          className="login_remindme"
          control={<Checkbox />}
          label={t("Remind Me")}
        />
        <Button
          className="button1"
          variant="contained"
          size="large"
          fullWidth
          type="submit"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : t("Login")}
        </Button>
        <div className="forgotpassword">
          <Link to="reset-password">{t("Forgot your password?")}</Link>
        </div>
      </Box>
    </>
  );
}

export default Login;
