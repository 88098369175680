import axios from 'axios';
import { API_URL } from '../../config/config';

//CategoryApi
export const categoryListApi = async (slug) => {
  const slugquery = slug ? `?slug=${slug}` : ""
  const response = await axios.get(`${API_URL}/categories${slugquery}`);
  return response.data;
};

export const faqListApi = async () => {
  const response = await axios.get(`${API_URL}/faq`);
  return response.data;
};

export const staticPageApi = async (slug) => {
  const response = await axios.get(`${API_URL}/pages?slug=${slug}`);
  return response.data;
};

export const contactUsApi = async (userData) => {
  const response = await axios.post(`${API_URL}/contact-us`, userData);
  return response.data;
};

export const blogListApi = async (page) => {
  const pageQuery = page ? `?page=${page}` : ""
  const response = await axios.get(`${API_URL}/blogs${pageQuery}`);
  return response.data;
};
export const productSearchApi = async (query) => {
  const pageQuery = query ? `?${query}` : ""
  const response = await axios.post(`${API_URL}/product-search${pageQuery}`);
  return response.data;
};

export const blogDetailApi = async (slug) => {
  const pageQuery = slug ? `?slug=${slug}` : ""
  const response = await axios.get(`${API_URL}/blog-details${pageQuery}`);
  return response.data;
};

// export const siteMapApi = async (slug) => {
//   const pageQuery = slug ? `?slug=${slug}` : ""
//   const response = await axios.get(`${API_URL}/site-map-urls`);
//   return response.data;
// };