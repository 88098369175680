import {
  Box,
  Container,
  Typography,
  CssBaseline,
  Divider,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import OfferNotificationBar from "../Common/OfferNotificationBar";
import { orderDetailApi } from "../../features/user/userApi";
import toast from "react-hot-toast";
import { Icon } from "@iconify/react/dist/iconify.js";

const OrderSuccess = () => {
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState([]);
  const [shippingAddress, setShippingAddress] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const fetchData = async (id) => {
    try {
      const orders = await orderDetailApi(id);
      if (orders.status) {
        setOrderData(orders?.data);
        const address = orders?.data?.shipping_address
          ? JSON.parse(orders?.data?.shipping_address)
          : "";
        setShippingAddress(address);
        window.localStorage.removeItem('cartData')
        window.localStorage.removeItem('checkOutResponse')
        window.dispatchEvent(new Event("cartUpdate"));
      } else {
        toast.error(orders?.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const orderId = window.localStorage.getItem("oId");
    fetchData(orderId);
  }, []);

  return (
    <>
      <OfferNotificationBar />
      <Header />
      <section>
      <Container maxWidth={"xl"}>
          <CssBaseline />
          <Box className="order_success_wrap">
            <Box className="osLeft">
              <Box className="order_nmbr">
                <svg width="207" height="207" viewBox="0 0 207 207" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M81.875 103.5L96.2917 117.917L125.125 89.0833M168.375 103.5C168.375 139.329 139.329 168.375 103.5 168.375C67.6705 168.375 38.625 139.329 38.625 103.5C38.625 67.6705 67.6705 38.625 103.5 38.625C139.329 38.625 168.375 67.6705 168.375 103.5Z" stroke="#34A853" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <rect width="207" height="207" rx="100" fill="#34A853" fill-opacity="0.15"></rect>
                </svg>
                {t("Order number")} #{" "} {orderData?.order_no}
              </Box>
              <Box className="order_thankYou">
                {t("Thank you for your purchase")}!
              </Box>
              <Box className="osDetail_bx">
                <Typography variant="h3" sx={{ mb: 2 }}>
                  {t("You order will be processed shortly")}
                </Typography>
                <ul>
                  <li>
                    {t(
                      "Order placed after 1:30pm will be dispatched the next day as DHL dispatches at 1:30pm"
                    )}
                  </li>
                  <li>
                    {t(
                      "Any order placed on Friday will not be dispatched on until Monday morning as these products must arrive within 24 hours."
                    )}
                  </li>
                  <li>{t("Slimming products are cold packed.")}</li>
                </ul>
              </Box>
              <Box className="osDetail_bx">
                <Typography variant="h3">{t("Your details")}</Typography>
                <Box class="userOrderDetailsBx" sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box className="userOrderDetails">
                    <Box sx={{ mt: 2 }}>
                      <Typography sx={{ fontWeight: 600 }}>{t("Name")}:</Typography>
                      <Typography>{`${shippingAddress?.first_name} ${shippingAddress?.last_name}`}</Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Typography sx={{ fontWeight: 600 }}>{t("Phone")}:</Typography>
                      <Typography>{shippingAddress?.phone}</Typography>
                    </Box>
                  </Box>
                  <Box className="userOrderDetails">
                  <Box sx={{ mt: 2 }}>
                      <Typography sx={{ fontWeight: 600 }}>{t("E-mail")}:</Typography>
                      <Typography>{orderData?.user?.email}</Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Typography sx={{ fontWeight: 600 }}>{t("Address")}:</Typography>
                      <Typography>{`${shippingAddress?.house_no} ${shippingAddress?.address} ${shippingAddress?.address_additional} ${shippingAddress?.postal_code} ${shippingAddress?.city}`}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="viewOrder_btn">
                <Button className="button1" variant="contained" href={`/my-account/dashboard/orders/${orderData?.id}`}>
                  {t("View order in your account")}
                </Button>
              </Box>
            </Box>
            <Box className="osRight">
              {orderData?.items?.length > 0 && (
                <>
                  {orderData?.items?.map((product, index) => (
                    <Box
                      className="osProduct_row"
                      key={`orderproduct${index}`}
                    >
                      <div className="osProduct_image">
                        <div className="osProduct_imgbx">
                          <img
                            src={product?.product?.images?.[0]?.file_path}
                            alt={product?.product?.name}
                          />
                          <span class="quantity">1</span>
                        </div>
                      </div>
                      <Box className="osProduct_content">
                        <Box className="osProduct_name">
                          <Typography className="osProduct_title" variant="h6">
                            {product?.product?.name} - {product?.quantity}
                          </Typography>
                          <Typography className="osProduct_price" variant="">€ {product?.total}</Typography>
                        </Box>
                        <Typography>
                          <span>{t("Strength")}:</span> {product?.strength}
                        </Typography>
                        <Typography>
                          <span>{t("Quantity in package")}:</span>{" "}
                          {product?.pack_qty}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
              <Box>
                <Box className="order_shipment">
                  <Typography>
                    <span>{t("Subtotal")}:{" "}</span>
                    <span><b>€ {orderData?.sub_total_amount}</b></span>
                  </Typography>
                  <Typography>
                    <span>{t("Shipment")}:{" "}</span>
                    <span><b>
                      {orderData?.shipping_cost > 0
                        ? orderData?.shipping_cost
                        : t("Free Shipping")}
                    </b></span>
                  </Typography>
                  <Typography>
                    <span>{t("Discount")}:{" "}</span>
                    <span><b>
                      {orderData?.discount_amount > 0
                        ? `- € ${orderData?.discount_amount}`
                        : `€ 0`}{" "}
                      
                    </b></span>
                  </Typography>
                  <Typography>
                    <span>{t("Prescription fee")}:{" "}</span>
                    <span><b>€ {orderData?.prescription_fee}</b></span>
                  </Typography>
                  <Divider sx={{ m: "20px 0" }} />
                  <Typography>
                    <span>{t("In total")}:</span>
                    <span><b>€ {orderData?.total_amount} </b></span>
                  </Typography>
                </Box>
              </Box>
              <Box className="orderChatnow">
                <Typography variant="h6">{t("Do you need help")}?</Typography>{" "}
                {/* Use translation for text */}
                <Button
                  variant="contained"
                  className="chatnow_btn"
                  id="customIntercomButton"
                >
                  <Icon icon="ri:chat-4-fill" />
                  {t("Chat with us now")}{" "}
                  {/* Use translation for button text */}
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default OrderSuccess;
